const data = {
  product: [
    "Product Id",
    "Category Name",
    "Title",
    "Description",
    "Brand",
    "CollegeName",
    "Condition",
    "Sale Price",
    "Shipping Cost Standard",
    "Shipping Cost Expedited",
    "Shipping Length",
    "Shipping Width",
    "Shipping Height",
    "Shipping Weight",
    "Product Group Image URL 1",
    "Product Group Image URL 2",
    "Product Group Image URL 3",
    "Product Group Image URL 4",
    "Video URL 1",
    "Video URL 2",
    "Video URL 3",
    "Video URL 4",
    "Material",
    "Length Unit",
    "Width Unit",
    "Price",
    "Color",
    "Size",
    "Quantity",
    "Listing Status"],
  Instructions: [
    {
      FieldDisplayName: "Product Id",
      DataType: "String",
      Importance: "Optional",
      AllowedValues: null,
      Description: "A unique ID associated to a product.",
    },
    {
      FieldDisplayName: "Category",
      DataType: "String",
      Importance: "Required",
      AllowedValues: null,
      Description:
        "Indicates the category of the product being submitted. Paysfer taxonomy is made up mostly of Google's (US) taxonomy structure, with added Resell-specific categories. If you already have Google categories for your products, we recommend to use those to make mapping the categories easier.",
    },

    {
      FieldDisplayName: "Title",
      DataType: "String",
      Importance: "Required",
      AllowedValues: null,
      Description:
        "Title of the product for listed items. Minimum characters required: 3. Maximum 50 characters permitted.",
    },
    {
      FieldDisplayName: "Description",
      DataType: "String",
      Importance: "Required",
      AllowedValues: null,
      Description:
        "Long description of the product. Minimum 50 characters needed and maximum 250 characters permitted.",
    },


    {
      FieldDisplayName: "Sale Price",
      DataType: "Numeric",
      Importance: "Optional",
      AllowedValues: null,
      Description:
        "The price of the product when it is on sale or when a discount is provided. Must be the same or less than price.",
    },
    {
      FieldDisplayName: "Shipping Cost Standard",
      DataType: "String",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "The cost for shipping this product via standard shipping.",
    },
    {
      FieldDisplayName: "Shipping Cost Expedited",
      DataType: "String",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "The cost for shipping this product via expedited shipping.",
    },
    {
      FieldDisplayName: "Shipping Length",
      DataType: "Numeric",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "Length dimension of the shipped package.",
    },
    {
      FieldDisplayName: "Shipping Width",
      DataType: "Numeric",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "Width dimension of the shipped package.",
    },
    {
      FieldDisplayName: "Shipping Height",
      DataType: "Numeric",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "Height dimension of the shipped package.",
    },
    {
      FieldDisplayName: "Shipping Weight",
      DataType: "Numeric",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "The shipping weight of the product/package.",
    },
    {
      FieldDisplayName: "Price",
      DataType: "Numeric",
      Importance: "Required",
      AllowedValues: null,
      Description: "The price to list for the product.",
    },
    {
      FieldDisplayName: "Quantity",
      DataType: "Numeric",
      Importance: "Required",
      AllowedValues: null,
      Description: "The available quantity of the product",
    },
    {
      FieldDisplayName: "Listing Status",
      DataType: "String",
      Importance: "Required",
      AllowedValues: null,
      Description: "Write 'live' if quantity is not 0 else write 'not live'",
    },
    {
      FieldDisplayName: "Size",
      DataType: "String",
      Importance: "Optional",
      AllowedValues:
        "0 | 1 | 10 | 10.5 | 11 | 11.5 | 12 | 14 | 16 | 18 | 2 | 20 | 24 | 28 | 3 | 30 | 32 | 34 | 36 | 38 | 4 | 40 | 42 | 44 | 46 | 48 | 5 | 5.5 | 6 | 6.5 | 7 | 7.5 | 8 | 8.5 | 9 | 9.5 | extralarge | l | large | m | medium | one-size | s | small | xl | xxl | xxxl",
      Description: "The size of the product.",
    },

    {
      FieldDisplayName: "Image URL 1",
      DataType: "URL string",
      Importance: "Required",
      AllowedValues: null,
      Description:
        "A good quality picture of the product atleast 1000 px square with at least 72 dpi. Only PNG and JPEG formats accepted.",
    },
    {
      FieldDisplayName: "Image URL 2",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description:
        "A different good quality picture of the product at least 1000 px square with at least 72 dpi. Only PNG and JPEG formats accepted.",
    },
    {
      FieldDisplayName: "Image URL 3",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description:
        "A different good quality picture of the product at least 1000 px square with at least 72 dpi. Only PNG and JPEG formats accepted.",
    },
    {
      FieldDisplayName: "Image URL 4",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description:
        "A different good quality picture of the product at least 1000 px square with at least 72 dpi. Only PNG and JPEG formats accepted.",
    },
    {
      FieldDisplayName: "Video URL 1",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description: "Video URL",
    },
    {
      FieldDisplayName: "Video URL 2",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description: "Video URL",
    },
    {
      FieldDisplayName: "Video URL 3",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description: "Video URL",
    },
    {
      FieldDisplayName: "Video URL 4",
      DataType: "URL string",
      Importance: "Optional",
      AllowedValues: null,
      Description: "Video URL",
    },
    {
      FieldDisplayName: "Brand",
      DataType: "String",
      Importance: "Recommended",
      AllowedValues: null,
      Description:
        "Indicates the brand of the product being submitted. Minimum of 3 characters, and maximum of 50 characters permitted.",
    },

    {
      FieldDisplayName: "Condition",
      DataType: "String",
      Importance: "Optional",
      AllowedValues: "New | Refurbished | Used",
      Description: "The condition of the product at the time of sale.",
    },
    {
      FieldDisplayName: "Color",
      DataType: "String",
      Importance: "Optional",
      AllowedValues:
        "beige | black | blue | brown | gold | green | grey | maroon | navy-blue | orange | pink | purple | red | rosegold | silver | tan | violet | white | yellow",
      Description:
        "The color of the product atleast 1000 px square with at least 72 dpi. Only PNG and JPEG formats accepted.",
    },
    {
      FieldDisplayName: "Material",
      DataType: "String",
      Importance: "Optional",
      AllowedValues: null,
      Description:
        "The material of the product. Can be made of multiple materials.",
    },

    {
      FieldDisplayName: "Length",
      DataType: "Numeric",
      Importance: "Recommended",
      AllowedValues: null,
      Description: "Length dimension of the shipped package.",
    },

    {
      FieldDisplayName: "Length Unit",
      DataType: "String",
      Importance: "Optional",
      AllowedValues: "cm | ft | in | km | m | mi | mm | yd",
      Description: "Unit of measure for Length (default: cm).",
    },
    {
      FieldDisplayName: "Width Unit",
      DataType: "String",
      Importance: "Optional",
      AllowedValues: "cm | ft | in | km | m | mi | mm | yd",
      Description: "Unit of measure for Width (default: cm).",
    },
  ],
  Example: [
    {
      "Product Id": "product_id1",
      "Category Name": "Sunglasses",
      Title: "title1",
      Description: "description1",
      "Sale Price": "500",
      "Shipping Cost Standard": "30",
      "Shipping Cost Expedited": "30",
      "Shipping Length": "23",
      "Shipping Width": "34",
      "Shipping Height": "23",
      "Shipping Weight": "56",
      Price: "89",
      Quantity: "900",
      "Product Group Image URL 1": "URL1",
      "Product Group Image URL 2": "URL2",
      "Product Group Image URL 3": "URL3",
      "Product Group Image URL 4": "URL4",
      "Video URL 1": "Video URL 1",
      "Video URL 2": "Video URL 2",
      "Video URL 3": "Video URL 3",
      "Video URL 4": "Video URL 4",
      Brand: "brand",
      "Country Of Origin": "US",
      Condition: "New",
      Color: "Red",
      Material: "Cotton",
      Gender: "Male",
      "Length Unit": "in",
      "Width Unit": "in",
    },
    {
      "Product Id": "product_id2",
      "Category Name": "Sunglasses",
      Title: "title1",
      Description: "description1",
      "Sale Price": "500",
      "Shipping Cost Standard": "30",
      "Shipping Cost Expedited": "30",
      "Shipping Length": "23",
      "Shipping Width": "34",
      "Shipping Height": "23",
      "Shipping Weight": "56",
      Price: "89",
      Quantity: "900",
      "Product Group Image URL 1": "URL1",
      "Product Group Image URL 2": "URL2",
      "Product Group Image URL 3": "URL3",
      "Product Group Image URL 4": "URL4",
      "Video URL 1": "Video URL 1",
      "Video URL 2": "Video URL 2",
      "Video URL 3": "Video URL 3",
      "Video URL 4": "Video URL 4",
      Brand: "brand",
      Condition: "New",
      Color: "Red",
      Material: "Cotton",
      "Length Unit": "in",
      "Width Unit": "in",
    },
    {
      "Product Id": "product_id3",
      "Category Name": "Sunglasses",
      Title: "title1",
      Description: "description1",
      "Sale Price": "500",
      "Shipping Cost Standard": "30",
      "Shipping Cost Expedited": "30",
      "Shipping Length": "23",
      "Shipping Width": "34",
      "Shipping Height": "23",
      "Shipping Weight": "56",
      Price: "89",
      Quantity: "900",
      "Product Group Image URL 1": "URL1",
      "Product Group Image URL 2": "URL2",
      "Product Group Image URL 3": "URL3",
      "Product Group Image URL 4": "URL4",
      "Video URL 1": "Video URL 1",
      "Video URL 2": "Video URL 2",
      "Video URL 3": "Video URL 3",
      "Video URL 4": "Video URL 4",
      Brand: "brand",
      Condition: "New",
      Color: "Red",
      Material: "Cotton",
      "Length Unit": "in",
      "Width Unit": "in",
    },,
    {
      "Product Id": "product_id4",
      "Category Name": "Sunglasses",
      Title: "title2",
      Description: "description2",
      "Sale Price": "500",
      "Shipping Cost Standard": "30",
      "Shipping Cost Expedited": "30",
      "Shipping Length": "23",
      "Shipping Width": "34",
      "Shipping Height": "23",
      "Shipping Weight": "56",
      Price: "89",
      Quantity: "900",
      "Product Group Image URL 1": "URL1",
      "Product Group Image URL 2": "URL2",
      "Product Group Image URL 3": "URL3",
      "Product Group Image URL 4": "URL4",
      "Video URL 1": "Video URL 1",
      "Video URL 2": "Video URL 2",
      "Video URL 3": "Video URL 3",
      "Video URL 4": "Video URL 4",
      Brand: "brand",
      Condition: "New",
      Color: "Red",
      Material: "Cotton",
      "Length Unit": "in",
      "Width Unit": "in",
    },
    {
      "Product Id": "product_id5",
      "Category Name": "Sunglasses",
      Title: "title3",
      Description: "description3",
      "Sale Price": "500",
      "Shipping Cost Standard": "30",
      "Shipping Cost Expedited": "30",
      "Shipping Length": "23",
      "Shipping Width": "34",
      "Shipping Height": "23",
      "Shipping Weight": "56",
      Price: "89",
      Quantity: "900",
      "Product Group Image URL 1": "URL1",
      "Product Group Image URL 2": "URL2",
      "Product Group Image URL 3": "URL3",
      "Product Group Image URL 4": "URL4",
      "Video URL 1": "Video URL 1",
      "Video URL 2": "Video URL 2",
      "Video URL 3": "Video URL 3",
      "Video URL 4": "Video URL 4",
      Brand: "brand",
      Condition: "New",
      Color: "Red",
      Material: "Cotton",
      "Length Unit": "in",
      "Width Unit": "in",
    },
  ],
};

export default data;
